.footer-container{
    position: relative;
}

.footer-container>hr{

    border: 1px solid rgb(247, 228, 192);
    
}

.footer{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20rem;
gap: 4rem;
}

.links{
    display: flex;
    gap: 4rem;
}
.links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
    

}

.footer-blur-1{
    width: 23rem;
    height: 15rem;
    filter: blur(200px);
    background-color: red;
    bottom: 0;
    right: 15%;
}
.footer-blur-2{
    width: 23rem;
    height: 15rem;
    filter: blur(200px);
    background-color: red;
    bottom: 0;
    right: 15%;
}
.footer-blur-1{
    width: 23rem;
    height: 15rem;
    filter: blur(200px);
    background-color: red;
    bottom: 0;
    left: 15%;
}


