.header-p{
    display: flex;
    gap: 9rem;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    color: white;
    font-style: italic;
    text-transform: uppercase;
}

.container-p{
    display: flex;
    flex-direction: column;
    margin-top: -4rem;
    padding: 0 2rem;
    gap: 4rem;
    position: relative;
}
.plans{
display: flex;
gap: 3rem;
justify-content: center;
align-items: center;
}
.plan{
display: flex;
flex-direction: column;
gap: 2rem;
background-color: var(--caloryCard);
color: white;
padding: 2rem;
width: 15rem;
}

.plans>:nth-child(2){
    background-color: var(--orange);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 2.5rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 1rem;
    font-weight: normal;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.plan-blur-1{
    width: 32rem;
    height: 23rem;
    left: 0;
    top: 6rem;
}
.plan-blur-2{
    width: 32rem;
    height: 23rem;
    right: 0;
    top: 10rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
}
.plans>:nth-child(2)>button{
   color: orange;
}

@media screen and (max-width: 768px) {
    .header-p{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        margin-top: 2rem;
        justify-content: center;
    }

    .plans{
        flex-direction: column;
    }

    .plans>:nth-child(2){
        transform: none;
    }
}
